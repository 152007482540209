<template>
  <v-dialog v-model="formModal.dialog" persistent width="700">
    <v-card>
      <v-card-title>
        <span class="text-h5">{{ formModal.title }}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              {{ formModal.description }}
              <v-textarea
                v-model="formModal.justification"
                label="Indoklás"
                required
              ></v-textarea>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn color="primary" text @click="$emit('closeFormDialog')">
          {{ $t("FORMS.cancel") }}
        </v-btn>

        <v-btn color="primary" elevation="2" @click="$emit('saveFormDialog')">
          {{ $t("FORMS.save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "FormModelDialog",
  props: ["formModal"],
  data: () => ({}),

  methods: {},
};
</script>
