<template>
  <v-dialog
    v-model="modalData.dialog"
    max-width="900px"
    persistent
    :retain-focus="false"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-if="permissionCan('create')"
        color="primary"
        dark
        class="mb-2"
        v-bind="attrs"
        v-on="on"
        @click="$emit('new')"
        style="width: 13rem"
      >
        {{ $t("MENU.NEW") }} SZABADSÁGIGÉNY
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <span class="headline">{{ formTitle }} </span>
            <v-btn @click="handleCloseModalForm" icon class="close-button">
              <v-icon> mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-form v-model="formValid" ref="form">
          <v-row>
            <v-col cols="12" sm="6" md="6">
              <v-btn
                v-if="
                  formModel.id &&
                  formModel.status == 0 &&
                  permissionCan('approval')
                "
                small
                style="color: #4caf50"
                @click.stop="handleApprove(formModel)"
              >
                <v-icon small>mdi-checkbox-marked-circle </v-icon>
                Engedélyezés
              </v-btn>

              <v-btn
                v-if="
                  formModel.id &&
                  formModel.status == 0 &&
                  permissionCan('approval')
                "
                class="ml-4"
                small
                style="color: #e33354"
                @click.stop="handleReject(formModel)"
              >
                <v-icon small>mdi-close-octagon </v-icon>
                Elutasítás
              </v-btn>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="12" sm="3" md="3">
              <v-select
                v-if="statuses.humanresources"
                v-model="formModel.status"
                :items="statuses.humanresources.holidayRequest"
                :label="$t('FORMS.status')"
                item-text="value"
                item-value="key"
                disabled
              >
                <template slot="selection" slot-scope="slotProps">
                  {{ $t("STATUSES." + slotProps.item.value) }}
                </template>
                <template v-slot:item="slotProps">
                  <span class="navi-text">{{
                    $t("STATUSES." + slotProps.item.value)
                  }}</span>
                </template>
              </v-select>
            </v-col>
          </v-row>
          <v-divider></v-divider>

          <v-row class="text-body-1">
            <v-col cols="12" sm="6" md="7">
              <v-select
                v-if="
                  (permissionCan('delete') || permissionCan('approval')) &&
                  !formModel.id
                "
                label="Kérelmező neve:"
                v-model="formModel.employee_id"
                :items="employeeCollection"
                item-text="fullName"
                item-value="id"
                @change="handleChangeYear"
              >
              </v-select>
              <p v-else>
                Kérelmező neve:
                <b>{{ getEmployeeByID(formModel.employee_id).fullName }}</b>
              </p>
              <p v-if="formModel.approval_id">
                Jóváhagyó:
                <b>{{ getEmployeeByID(formModel.approval_id).fullName }}</b>
              </p>
              <p>
                <!-- {{ modalInfo.available_days }} -->
              </p>

              <v-textarea
                v-if="formModel.justification"
                outlined
                v-model="formModel.justification"
                label="Indoklás"
                disabled
              ></v-textarea>
              <v-select
                label="Válasszon évet"
                :disabled="formModel.id"
                v-model="formModel.year"
                :items="yearCollection"
                item-text="year"
                item-value="id"
                :rules="requiredRules"
                @change="handleChangeYear"
              ></v-select>
              <v-textarea
                class="mt-3"
                outlined
                v-model="formModel.comment"
                label="Megjegyzés"
                :disabled="formModel.status != 0"
              ></v-textarea>
            </v-col>

            <v-col cols="12" sm="6" md="5">
              <p>
                Szabadság keret: <b>{{ modalInfo.available_days }}</b>
              </p>
              <p>
                Felhasználható keret:

                <b
                  >{{
                    modalInfo.num_of_remaining_holiday -
                    getSelectedDays.length +
                    formModel.num_of_days
                  }}
                </b>
              </p>
              <p>
                Kiválasztott napok száma: <b>{{ getSelectedDays.length }}</b>
              </p>
              <p v-if="selectedRange.length > 0">
                Szabadság dátuma:
                <b>{{ selectedRange[0] }} - {{ selectedRange[1] }}</b>
              </p>
              <v-card
                v-if="
                  formModel.year &&
                  getHolidays.length > 0 &&
                  formModel.employee_id
                "
              >
                <!-- <v-card-title>
                  <h1>Datumok</h1>
                </v-card-title> -->
                <v-card-text>
                  <v-date-picker
                    :first-day-of-week="1"
                    v-model="selectedRange"
                    :allowed-dates="allowedDates"
                    no-title
                    range
                    scrollable
                    locale="hu"
                    :min="minDate"
                    :max="maxDate"
                    color="primary"
                    :disabled="formModel.status !== 0"
                  ></v-date-picker>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          v-if="
            (permissionCan('create') && !formModel.id) ||
            permissionCan('update')
          "
          color="primary"
          elevation="2"
          :disabled="canSave"
          @click="handleSaveModalForm"
        >
          {{ $t("FORMS.save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <FormModalDialog
      :formModal="formModal"
      @closeFormDialog="closeFormDialog"
      @saveFormDialog="saveFormDialog"
    >
    </FormModalDialog>
    <v-overlay :value="loader">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { ENDPOINT, PERMISSION_TO } from "./HolidayRequests";
import i18nService from "@/core/services/i18n.service.js";
import ApiService from "@/core/services/api.service";
import { formModelMixins } from "@/view/mixins/formModelMixins.js";
import FormModalDialog from "@/view/components/FormModalDialog";

export const initialFormData = () => ({
  id: null,
  status: 0,
  year: null,
  employee_id: null,
  date_from: null,
  date_to: null,
  num_of_days: 0,
  comment: "",
  days: [],
});

export default {
  name: "HolidayRequestModalForm",
  props: [
    "modalData",
    "permissions",
    "userGroups",
    "employee",
    "yearCollection",
    "employeeCollection",
  ],
  components: {
    FormModalDialog,
  },
  // components: { DateRangePicker },
  mixins: [formModelMixins],
  data() {
    return {
      loader: false,
      languages: i18nService.languages,
      selectedLocale: i18nService.languages.find((item) => {
        return item.lang == i18nService.getActiveLanguage();
      }),
      formModel: Object.assign({}, initialFormData()),
      permissionTo: PERMISSION_TO,
      endPoint: ENDPOINT,

      formValid: false,
      messages: {},
      nameRules: [
        (v) => !!v || "This field is required",
        (v) =>
          (!!v && v.length < 255) || "This field must be max 255 characters",
      ],
      requiredRules: [(v) => !!v || "This field is required"],
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],

      selectedDate: null,
      menuFrom: false,

      menuTo: false,
      selectedRange: [],
      selectedYear: null,
      holidayCollection: [],
      employeeDays: [],

      filterData: {
        employee_id: null,
        year: null,
      },
      formModal: {
        id: null,
        dialog: false,
        url: "",
        title: "",
        description: "",
        justification: "",
      },
    };
  },
  computed: {
    ...mapGetters(["statuses"]),
    minDate() {
      if (!this.formModel.year) return null;

      return this.formModel.year + "-01-01";
    },
    maxDate() {
      if (!this.formModel.year) return null;
      return this.formModel.year + 1 + "-12-31";
      // return this.formModel.year + "-05-31";
    },

    getHolidays() {
      if (!this.maxDate || !this.minDate) return [];
      const start = new Date(this.minDate);
      const end = new Date(this.maxDate);
      let holidays = [];
      for (
        let date = new Date(start);
        date <= end;
        date.setDate(date.getDate() + 1)
      ) {
        if (date.getDay() == 0 || date.getDay() == 6) {
          holidays.push(this.formattedDate(date));
        }
      }

      if (this.selectedYear) {
        this.selectedYear.special_days.forEach((element) => {
          const day = new Date(element.day);
          const formattedDate = this.formattedDate(day);
          if (element.non_working_day == 1) {
            holidays.push(formattedDate);
          } else {
            holidays = [...holidays.filter((i) => i != formattedDate)];
          }
        });
      }

      return holidays;
    },

    getSelectedDays() {
      if (this.getHolidays.length == 0) return [];

      const selectedRange = this.selectedRange;

      selectedRange.sort();

      const start = new Date(selectedRange[0]);
      const end = new Date(selectedRange[1]);
      let selectedDays = [];
      for (
        let date = new Date(start);
        date <= end;
        date.setDate(date.getDate() + 1)
      ) {
        const formattedDate = this.formattedDate(date);

        if (
          ![...this.getHolidays, ...this.reservedEmployeeDays].find(
            (d) => d == formattedDate
          )
        ) {
          selectedDays.push(formattedDate);
        }
      }
      return selectedDays;
    },

    formTitle() {
      return this.formModel.id
        ? "Szerkesztés "
        : this.$t("MENU.NEW") + " " + this.$t("MENU.holidayRequest");
    },

    initialFormData() {
      return initialFormData;
    },
    allowedDates() {
      const disabledDates = [
        ...this.getHolidays,
        ...this.avialableRequestDates,
        ...this.reservedEmployeeDays,
      ];
      return (date) => !disabledDates.includes(date);
    },

    avialableRequestDates() {
      if (
        !this.holidayCollection ||
        !this.holidayCollection[0] ||
        this.selectedRange.length != 1
      )
        return [];

      let avialableRequestDates = [];
      let numOfRemainingHoliday =
        this.holidayCollection[0].num_of_remaining_holiday +
        this.formModel.num_of_days;
      const start = new Date(this.selectedRange[0]);
      const end = new Date(this.maxDate);

      const startToSelectedDate = new Date(this.minDate);
      let toSelectedDate = new Date(this.selectedRange[0]);
      toSelectedDate = toSelectedDate.setDate(toSelectedDate.getDate() - 1);
      for (
        let date = new Date(startToSelectedDate);
        date < toSelectedDate;
        date.setDate(date.getDate() + 1)
      ) {
        const formattedDate = this.formattedDate(date);
        if (!this.getHolidays.find((d) => d == formattedDate)) {
          avialableRequestDates.push(formattedDate);
        }
      }

      let i = 0;
      for (
        let date = new Date(start);
        date <= end;
        date.setDate(date.getDate() + 1)
      ) {
        const formattedDate = this.formattedDate(date);
        if (
          ![...this.getHolidays, ...this.reservedEmployeeDays].find(
            (d) => d == formattedDate
          )
        ) {
          i++;
          if (numOfRemainingHoliday < i) {
            avialableRequestDates.push(formattedDate);
          }
        }
      }

      return avialableRequestDates;
    },

    reservedEmployeeDays() {
      return this.employeeDays.map((date) => {
        if (this.formModel.id != date.holiday_request_id && date.status != 2)
          return this.formattedDate(new Date(date.day));

        return null;
      });
    },

    canSave() {
      return !(
        this.formModel.employee_id &&
        this.formModel.year &&
        this.getSelectedDays.length > 0
      );
    },

    modalInfo() {
      if (!this.holidayCollection || !this.holidayCollection[0]) {
        return {
          available_days: null,
          num_of_approved_holiday: null,
          num_of_rejected_holiday: null,
          num_of_remaining_holiday: null,
          num_of_requested_holiday: null,
        };
      }
      return this.holidayCollection[0];
    },
  },
  watch: {
    modalData: {
      deep: true,
      handler(value) {
        this.selectedLocale = i18nService.languages.find((item) => {
          return item.lang == i18nService.getActiveLanguage();
        });
        this.loader = true;
        this.selectedRange = [];
        this.selectedYear = null;

        if (value.editedId) {
          ApiService.get(ENDPOINT + value.editedId)
            .then(({ data }) => {
              this.selectedRange = [
                this.formattedDate(new Date(data.date_from)),
                this.formattedDate(new Date(data.date_to)),
              ];
              this.$nextTick(() => {
                this.formModel = Object.assign({}, data);
                this.fetchHolidayRequest();
              });
              this.loader = false;
            })
            .catch((error) => {
              console.log("Error!: ", error);
            });
        } else {
          this.formModel = Object.assign({}, initialFormData());

          if (this.employee) {
            this.formModel.employee_id = this.employee.id;
          }
          this.holidayCollection = [];

          if (this.$refs.form) this.$refs.form.resetValidation();
          this.loader = false;
        }
        this.resetErrorMessages();
      },
    },
  },
  methods: {
    ...mapActions([
      // "fetchYear", "fetchEmployee"
    ]),

    handleSaveModalForm() {
      let model = Object.assign({}, this.formModel);
      model.days = this.getSelectedDays;
      model.num_of_days = model.days.length;
      model.date_from = this.selectedRange[0];
      model.date_to = this.selectedRange[1];

      this.$refs.form.validate();

      if (this.formValid) {
        this.resetErrorMessages();
        // this.modalData.loading = true;
        // this.formModel.birth_date = this.selectedDate;
        this.loader = true;
        if (model.id) {
          ApiService.put(ENDPOINT + model.id, model)
            .then(() => {
              this.$emit("saveModalForm");
            })
            .catch((error) => {
              if (error.response) {
                let errors = error.response.data;
                if (errors) {
                  for (let field in errors.errors) {
                    this.setError(field, errors.errors[field][0]);
                  }
                }
              }
            })
            .finally(() => {
              this.loader = false;
            });
        } else {
          //create model
          ApiService.post(ENDPOINT, model)
            .then(() => {
              this.$emit("saveModalForm");
            })
            .catch((error) => {
              if (error.response) {
                let errors = error.response.data;
                if (errors) {
                  for (let field in errors) {
                    this.setError(field, errors[field][0]);
                  }
                }
              } else if (error.request) {
                // The request was made but no response was received
                console.log(error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log("Error", error.message);
              }
              console.log("Error!: ", error);
              // this.modalData.loading = false;
            })
            .finally(() => {
              this.loader = false;
            });
        }
      }
    },

    fetchHolidayRequest() {
      this.loader = true;
      return new Promise((resolve) => {
        this.fetchHoliday();
        ApiService.get("humanResources/year/" + this.formModel.year)
          .then(({ data }) => {
            this.selectedYear = Object.assign({}, data);
          })
          .catch((error) => {
            console.log("Error!: ", error);
          });

        let filterData = {
          year: this.formModel.year,
          employee_id: this.formModel.employee_id
            ? this.formModel.employee_id
            : this.employee.id,
        };

        return ApiService.post(
          "humanResources/holiday-request/employee-days",
          filterData
        )
          .then(({ data }) => {
            this.employeeDays = data;
          })
          .catch((error) => {
            console.log("Error!: ", error);
          })
          .finally(() => {
            this.loadingTable = false;
            this.loader = false;
            resolve();
          });
      });
    },

    fetchHoliday() {
      this.loader = true;
      this.loadingTable = true;
      this.holidays = [];
      this.filterData.employee_id = this.formModel.employee_id;
      this.filterData.year = this.formModel.year;
      ApiService.post("humanResources/holiday", this.filterData)
        .then(({ data }) => {
          this.holidayCollection = data;
        })
        .catch((error) => {
          console.log("Error!: ", error);
        })
        .finally(() => {
          this.loadingTable = false;
          this.loader = false;
        });
    },

    fetchModel() {
      return null;
    },

    isHoliday(date) {
      let getDay = date.getDay();
      if (getDay === 0 || getDay === 6) {
        return true;
      }
      return false;
    },

    formattedDate(d) {
      const year = d.getFullYear().toString();
      const month = (d.getMonth() + 1).toString().padStart(2, "0");
      const dayOfMonth = d.getDate().toString().padStart(2, "0");

      return `${year}-${month}-${dayOfMonth}`;
    },

    getEmployeeByID(id) {
      if (!id) return { fullName: "" };
      let item = this.employeeCollection.find((item) => item.id == id);
      if (!item) {
        item = { fullName: "" };
      }
      return item;
    },

    closeFormDialog() {
      this.formModal.id = null;
      this.formModal.dialog = false;
      this.formModal.url = "";
      this.formModal.inputText = "";
    },

    saveFormDialog() {
      this.loader = true;
      ApiService.put(this.formModal.url, {
        holiday_requests: [
          {
            id: this.formModal.id,
            justification: this.formModal.justification,
          },
        ],
      })
        .then(() => {
          // this.$emit("saveModalForm");
          this.handleCloseModalForm();
        })
        .catch((error) => {
          if (error.response) {
            let errors = error.response.data;
            if (errors) {
              for (let field in errors) {
                this.setError(field, errors[field][0]);
              }
            }
          } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
          }
          console.log("Error!: ", error);
          // this.modalData.loading = false;
        })
        .finally(() => {
          this.closeFormDialog();
          this.loader = false;
        });
    },

    handleApprove(item) {
      this.formModal.id = item.id;
      this.formModal.dialog = true;
      this.formModal.url = "humanResources/holiday-request/approve";
      this.formModal.title = "Engedélyezés";
      this.formModal.description = "Írja be az indoklást.";
      this.formModal.justification = "";
    },
    handleReject(item) {
      this.formModal.id = item.id;
      this.formModal.dialog = true;
      this.formModal.url = "humanResources/holiday-request/reject";
      this.formModal.title = "Elutasítás";
      this.formModal.description = "Írja be az indoklást.";
      this.formModal.justification = "";
    },

    handleChangeYear() {
      if (!this.formModel.employee_id || !this.formModel.year) return null;

      this.fetchHolidayRequest().then(() => {
        if (!this.formModel.id) {
          this.selectedRange = [];
          var today = new Date();
          var year = today.getFullYear();
          this.$nextTick(() => {
            if (year == this.formModel.year) {
              var month = ("0" + (today.getMonth() + 1)).slice(-2);
              var day = ("0" + today.getDate()).slice(-2);
              this.selectedRange = [
                year + "-" + month + "-" + day,
                year + "-" + month + "-" + day,
              ];
            } else {
              this.selectedRange = [
                this.formModel.year + "-01-01",
                this.formModel.year + "-01-01",
              ];
            }
          });
        }
      });
    },
  },

  mounted() {
    this.fetchModel();
  },
};
</script>
