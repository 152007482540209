var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"data-app":""}},[_c('v-card',[_c('v-card-title',[_c('v-row',[_c('v-col',{staticClass:"ml-4",staticStyle:{"color":"#e33354"},attrs:{"cols":"12","sm":"6","md":"6"}},[_c('h3',[_vm._v(_vm._s(_vm.cardTitle))])]),_c('v-spacer'),_c('v-col',{attrs:{"cols":"12","sm":"3","md":"3"}},[_c('v-text-field',{attrs:{"append-icon":"search","label":"Keresés","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.filteredHolidayRequestCollection,"search":_vm.search,"sort-by":['date_from'],"sort-desc":"true","show-select":_vm.permissionCan('approval') && _vm.filterData.status == 0,"loading":_vm.loadingTable,"footer-props":{
          'items-per-page-options': [100],
        }},on:{"click:row":_vm.handleClickItem},scopedSlots:_vm._u([{key:"header",fn:function(ref){
        var headers = ref.props.headers;
return [_c('thead',{staticClass:"v-data-table-header"},[_c('tr',[(_vm.permissionCan('approval') && _vm.filterData.status == 0)?_c('th'):_vm._e(),(_vm.permissionCan('approval') && _vm.filterData.status == 0)?_c('th',[(_vm.selectedHolidayRequests.length > 0)?_c('v-icon',{staticClass:"mr-2",staticStyle:{"color":"#4caf50"},attrs:{"small":""},on:{"click":function($event){$event.stopPropagation();return _vm.handleApproveAll(_vm.item)}}},[_vm._v("mdi-checkbox-marked-circle ")]):_vm._e(),(_vm.selectedHolidayRequests.length > 0)?_c('v-icon',{staticStyle:{"color":"#e33354"},attrs:{"small":""},on:{"click":function($event){$event.stopPropagation();return _vm.handleRejectAll(_vm.item)}}},[_vm._v(" mdi-close-octagon ")]):_vm._e()],1):_vm._e(),_c('th',[_c('v-select',{attrs:{"items":[
                    { id: null, year: 'Minden év' } ].concat( _vm.yearCollection ),"item-text":"year","item-value":"id"},on:{"change":_vm.fetchHolidayRequest},model:{value:(_vm.filterData.year),callback:function ($$v) {_vm.$set(_vm.filterData, "year", $$v)},expression:"filterData.year"}})],1),_c('th',[_c('v-select',{attrs:{"items":[
                    { id: null, fullName: 'Összes munkatárs' } ].concat( _vm.employeeCollection ),"item-text":"fullName","item-value":"id","disabled":!(_vm.permissionCan('approval') || _vm.permissionCan('delete'))},on:{"change":_vm.fetchHolidayRequest},model:{value:(_vm.filterData.employee_id),callback:function ($$v) {_vm.$set(_vm.filterData, "employee_id", $$v)},expression:"filterData.employee_id"}})],1),_c('th',{attrs:{"colspan":"2"}},[_c('v-select',{attrs:{"items":[
                    { key: null, value: 'Minden hónap' } ].concat( _vm.monthCollection ),"item-text":"value","item-value":"key"},model:{value:(_vm.filterData.month),callback:function ($$v) {_vm.$set(_vm.filterData, "month", $$v)},expression:"filterData.month"}})],1),_c('th'),_c('th'),_c('th',[_c('v-select',{attrs:{"items":[
                    { key: null, value: 'Minden státusz' } ].concat( _vm.statusCollection ),"item-text":"value","item-value":"key"},on:{"change":_vm.fetchHolidayRequest},model:{value:(_vm.filterData.status),callback:function ($$v) {_vm.$set(_vm.filterData, "status", $$v)},expression:"filterData.status"}})],1),_c('th',[(_vm.canSave)?_c('v-btn',{attrs:{"color":"primary","elevation":"2"},on:{"click":_vm.handleSaveData}},[_vm._v(" "+_vm._s(_vm.$t("FORMS.save"))+" ")]):_vm._e()],1),_c('th')])])]}},{key:"item.status",fn:function(ref){
                  var item = ref.item;
return [(_vm.statuses.humanresources.holidayRequest)?_c('span',[_vm._v(_vm._s(_vm.$t( "STATUSES." + _vm.statuses.humanresources.holidayRequest[item.status].value )))]):_vm._e()]}},{key:"item.date_from",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formattedDate(new Date(item.date_from)))+" ")]}},{key:"item.date_to",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formattedDate(new Date(item.date_to)))+" ")]}},{key:"item.employee_id",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getEmployeeByID(item.employee_id) ? _vm.getEmployeeByID(item.employee_id).fullName : "")+" ")]}},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('HolidayRequestModalForm',{attrs:{"modalData":_vm.modalData,"userGroups":_vm.userGroupCollection,"permissions":_vm.permissions,"statuses":_vm.statuses,"employee":_vm.employee,"canCreate":_vm.canCreate,"yearCollection":_vm.yearCollection,"employeeCollection":_vm.employeeCollection},on:{"closeModalForm":_vm.handleCloseModalForm,"saveModalForm":_vm.handleSaveModalForm,"new":_vm.handleNew}}),(_vm.permissionCan('approval') || _vm.permissionCan('delete'))?_c('a',{staticClass:"mb-2 ml-4 grey--text",class:{
                active:
                  _vm.filterData &&
                  _vm.filterData.employee_id == null &&
                  _vm.filterData.status == 0,
              },attrs:{"href":"#"},on:{"click":function($event){$event.stopPropagation();return _vm.handleWaitForApproove.apply(null, arguments)}}},[_vm._v(" Összes beküldött igény")]):_vm._e(),(_vm.permissionCan('approval') || _vm.permissionCan('delete'))?_c('a',{staticClass:"mb-2 ml-4 grey--text",class:{
                active:
                  _vm.employee &&
                  _vm.filterData &&
                  _vm.filterData.employee_id == _vm.employee.id &&
                  _vm.filterData.status == null,
              },attrs:{"href":"#"},on:{"click":function($event){$event.stopPropagation();return _vm.handleMyRequests.apply(null, arguments)}}},[_vm._v(" Saját igényeim")]):_vm._e(),_c('DeleteModalDialog',{attrs:{"dialogDelete":_vm.dialogDelete},on:{"closeDelete":_vm.handleCloseDelete,"deleteItemConfirm":_vm.handleDeleteItemConfirm}})],1)]},proxy:true},{key:"item.actions",fn:function(ref){
              var item = ref.item;
return [(_vm.permissionCan('delete'))?_c('v-icon',{staticClass:"mr-2",attrs:{"color":"primary","small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")]):_vm._e()]}},{key:"item.approve",fn:function(ref){
              var item = ref.item;
return [(item.status == 0 && _vm.permissionCan('approval'))?_c('v-icon',{staticClass:"mr-2",staticStyle:{"color":"#4caf50"},attrs:{"small":""},on:{"click":function($event){$event.stopPropagation();return _vm.handleApprove(item)}}},[_vm._v("mdi-checkbox-marked-circle ")]):_vm._e(),(item.status == 0 && _vm.permissionCan('approval'))?_c('v-icon',{staticStyle:{"color":"#e33354"},attrs:{"small":""},on:{"click":function($event){$event.stopPropagation();return _vm.handleReject(item)}}},[_vm._v(" mdi-close-octagon ")]):_vm._e()]}}]),model:{value:(_vm.selectedHolidayRequests),callback:function ($$v) {_vm.selectedHolidayRequests=$$v},expression:"selectedHolidayRequests"}}),_c('FormModalDialog',{attrs:{"formModal":_vm.formModal},on:{"closeFormDialog":_vm.closeFormDialog,"saveFormDialog":_vm.saveFormDialog}}),_c('v-dialog',{attrs:{"persistent":"","width":"700"},model:{value:(_vm.approveAllModal.dialog),callback:function ($$v) {_vm.$set(_vm.approveAllModal, "dialog", $$v)},expression:"approveAllModal.dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.approveAllModal.title))])]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.closeApproveAllModalDialog}},[_vm._v(" "+_vm._s(_vm.$t("FORMS.cancel"))+" ")]),_c('v-btn',{attrs:{"color":"primary","elevation":"2"},on:{"click":_vm.saveApproveAllModalDialog}},[_vm._v(" "+_vm._s(_vm.$t("FORMS.save"))+" ")])],1)],1)],1)],1),_c('SnackBarInfoComponent',{attrs:{"snackbarInfo":_vm.snackbarInfo}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }