<template>
  <div data-app>
    <v-card>
      <v-card-title>
        <v-row>
          <v-col cols="12" sm="6" md="6" style="color: #e33354" class="ml-4">
            <h3>{{ cardTitle }}</h3>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="12" sm="3" md="3">
            <v-text-field
              v-model="search"
              append-icon="search"
              label="Keresés"
              single-line
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-data-table
          v-model="selectedHolidayRequests"
          :headers="headers"
          :items="filteredHolidayRequestCollection"
          :search="search"
          :sort-by="['date_from']"
          sort-desc="true"
          :show-select="permissionCan('approval') && filterData.status == 0"
          @click:row="handleClickItem"
          :loading="loadingTable"
          :footer-props="{
            'items-per-page-options': [100],
          }"
        >
          <template #header="{ props: { headers } }">
            <thead class="v-data-table-header">
              <tr>
                <th
                  v-if="permissionCan('approval') && filterData.status == 0"
                ></th>
                <th v-if="permissionCan('approval') && filterData.status == 0">
                  <v-icon
                    small
                    style="color: #4caf50"
                    class="mr-2"
                    @click.stop="handleApproveAll(item)"
                    v-if="selectedHolidayRequests.length > 0"
                    >mdi-checkbox-marked-circle
                  </v-icon>

                  <v-icon
                    small
                    style="color: #e33354"
                    @click.stop="handleRejectAll(item)"
                    v-if="selectedHolidayRequests.length > 0"
                  >
                    mdi-close-octagon
                  </v-icon>
                </th>
                <th>
                  <v-select
                    v-model="filterData.year"
                    :items="[
                      { id: null, year: 'Minden év' },
                      ...yearCollection,
                    ]"
                    item-text="year"
                    item-value="id"
                    @change="fetchHolidayRequest"
                  ></v-select>
                </th>
                <th>
                  <v-select
                    v-model="filterData.employee_id"
                    :items="[
                      { id: null, fullName: 'Összes munkatárs' },
                      ...employeeCollection,
                    ]"
                    item-text="fullName"
                    item-value="id"
                    :disabled="
                      !(permissionCan('approval') || permissionCan('delete'))
                    "
                    @change="fetchHolidayRequest"
                  ></v-select>
                </th>
                <th colspan="2">
                  <v-select
                    v-model="filterData.month"
                    :items="[
                      { key: null, value: 'Minden hónap' },
                      ...monthCollection,
                    ]"
                    item-text="value"
                    item-value="key"
                  ></v-select>
                </th>
                <th></th>
                <th></th>
                <th>
                  <v-select
                    v-model="filterData.status"
                    :items="[
                      { key: null, value: 'Minden státusz' },
                      ...statusCollection,
                    ]"
                    item-text="value"
                    item-value="key"
                    @change="fetchHolidayRequest"
                  ></v-select>
                </th>

                <th>
                  <v-btn
                    v-if="canSave"
                    color="primary"
                    elevation="2"
                    @click="handleSaveData"
                  >
                    {{ $t("FORMS.save") }}
                  </v-btn>
                </th>
                <th></th>
              </tr>
            </thead>
          </template>

          <template v-slot:item.status="{ item }">
            <span v-if="statuses.humanresources.holidayRequest">{{
              $t(
                "STATUSES." +
                  statuses.humanresources.holidayRequest[item.status].value
              )
            }}</span>
          </template>
          <template v-slot:item.date_from="{ item }">
            {{ formattedDate(new Date(item.date_from)) }}
          </template>
          <template v-slot:item.date_to="{ item }">
            {{ formattedDate(new Date(item.date_to)) }}
          </template>
          <template v-slot:item.employee_id="{ item }">
            {{
              getEmployeeByID(item.employee_id)
                ? getEmployeeByID(item.employee_id).fullName
                : ""
            }}
          </template>
          <template v-slot:top>
            <v-toolbar flat>
              <HolidayRequestModalForm
                :modalData="modalData"
                :userGroups="userGroupCollection"
                :permissions="permissions"
                :statuses="statuses"
                :employee="employee"
                :canCreate="canCreate"
                :yearCollection="yearCollection"
                :employeeCollection="employeeCollection"
                @closeModalForm="handleCloseModalForm"
                @saveModalForm="handleSaveModalForm"
                @new="handleNew"
              ></HolidayRequestModalForm>
              <!-- <v-spacer></v-spacer> -->
              <a
                class="mb-2 ml-4 grey--text"
                v-if="permissionCan('approval') || permissionCan('delete')"
                :class="{
                  active:
                    filterData &&
                    filterData.employee_id == null &&
                    filterData.status == 0,
                }"
                href="#"
                @click.stop="handleWaitForApproove"
              >
                Összes beküldött igény</a
              >
              <a
                class="mb-2 ml-4 grey--text"
                :class="{
                  active:
                    employee &&
                    filterData &&
                    filterData.employee_id == employee.id &&
                    filterData.status == null,
                }"
                v-if="permissionCan('approval') || permissionCan('delete')"
                href="#"
                @click.stop="handleMyRequests"
              >
                Saját igényeim</a
              >
              <DeleteModalDialog
                :dialogDelete="dialogDelete"
                @closeDelete="handleCloseDelete"
                @deleteItemConfirm="handleDeleteItemConfirm"
              >
              </DeleteModalDialog>
            </v-toolbar>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon
              color="primary"
              v-if="permissionCan('delete')"
              small
              @click="deleteItem(item)"
              class="mr-2"
            >
              mdi-delete
            </v-icon>
          </template>
          <template v-slot:item.approve="{ item }">
            <v-icon
              v-if="item.status == 0 && permissionCan('approval')"
              small
              style="color: #4caf50"
              class="mr-2"
              @click.stop="handleApprove(item)"
              >mdi-checkbox-marked-circle
            </v-icon>

            <v-icon
              v-if="item.status == 0 && permissionCan('approval')"
              small
              style="color: #e33354"
              @click.stop="handleReject(item)"
            >
              mdi-close-octagon
            </v-icon>
          </template>
        </v-data-table>
        <FormModalDialog
          :formModal="formModal"
          @closeFormDialog="closeFormDialog"
          @saveFormDialog="saveFormDialog"
        >
        </FormModalDialog>

        <v-dialog v-model="approveAllModal.dialog" persistent width="700">
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ approveAllModal.title }}</span>
            </v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn color="primary" text @click="closeApproveAllModalDialog">
                {{ $t("FORMS.cancel") }}
              </v-btn>

              <v-btn
                color="primary"
                elevation="2"
                @click="saveApproveAllModalDialog"
              >
                {{ $t("FORMS.save") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card-text>
      <SnackBarInfoComponent :snackbarInfo="snackbarInfo">
      </SnackBarInfoComponent>
    </v-card>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import { mapGetters, mapActions } from "vuex";
import HolidayRequestModalForm, {
  initialFormData,
} from "./HolidayRequestModalForm";
import DeleteModalDialog from "@/view/components/DeleteModalDialog";
import FormModalDialog from "@/view/components/FormModalDialog";
import { listModelsMixins } from "@/view/mixins/listModelsMixins.js";
export const ENDPOINT = "humanResources/holiday-request/";
export const PERMISSION_TO = "humanResources.holidayRequest.";
import SnackBarInfoComponent from "@/view/components/SnackBarInfoComponent";

const INITIAL_FILTER_DATA = {
  year: new Date().getFullYear(),
  status: null,
  employee_id: null,
  month: null,
};

export default {
  name: "holidayRequests",
  components: {
    HolidayRequestModalForm,
    DeleteModalDialog,
    FormModalDialog,
    SnackBarInfoComponent,
  },
  mixins: [listModelsMixins],
  data() {
    return {
      routeName: "holidayRequests",
      routePath: "/humanResource/holidayRequests/",
      permissionTo: PERMISSION_TO,

      cardTitle: this.$t("MENU.holidayRequest"),
      search: "",
      permissions: JSON.parse(localStorage.permissions),
      modalData: {
        dialog: false,
        editedId: null,
      },

      formModal: {
        id: null,
        dialog: false,
        url: "",
        title: "",
        description: "",
        justification: "",
      },
      editedItem: Object.assign({}, initialFormData()),
      dialogDelete: false,
      loadingTable: true,
      endPoint: ENDPOINT,
      employee: null,
      holidayRequestCollection: [],
      filterData: Object.assign({}, INITIAL_FILTER_DATA),
      statusCollection: [
        // { key: null, value: "Válassz státuszt" },
        { key: 0, value: "Beküldött" },
        { key: 1, value: "Elfogadott" },
        { key: 2, value: "Elutasított" },
      ],
      monthCollection: [
        { key: null, value: "Válassz hónapot" },
        { key: "01", value: "Január" },
        { key: "02", value: "Február" },
        { key: "03", value: "Március" },
        { key: "04", value: "Április" },
        { key: "05", value: "Május" },
        { key: "06", value: "Június" },
        { key: "07", value: "Július" },
        { key: "08", value: "Augusztus" },
        { key: "09", value: "Szeptember" },
        { key: "10", value: "Október" },
        { key: "11", value: "November" },
        { key: "12", value: "December" },
      ],
      holidayCollection: [],
      selectedHolidayRequests: [],

      approveAllModal: {
        dialog: false,
        url: "",
        title: "",
        description: "",
      },
    };
  },
  computed: {
    ...mapGetters([
      "employeeCollection",
      "yearCollection",
      "getEmployeeByID",
      "userGroupCollection",
      "currentUser",
      "statuses",
    ]),

    canCreate(item) {
      if (!this.employee) {
        return false;
      }

      return (
        this.permissionCan("create") && item.num_of_days > 0 && item.status != 2
      );
    },

    headers() {
      let approve = [];
      if (this.permissionCan("approval") && this.filterData.status == 0) {
        approve = [
          {
            text: "Engedélyezés",
            value: "approve",
            width: "90px",
          },
        ];
      }

      let headers = [
        {
          text: "Munkaév",
          value: "year",
          width: "120px",
        },

        {
          text: "Munkatárs neve",
          value: "employee",
        },
        { text: "Szabadság kezdete", value: "date_from" },
        { text: "Szabadság vége", value: "date_to" },
        { text: "Napok száma", value: "num_of_days" },
        // { text: "megjegyzés", value: "comment" },
        {
          text: "Engedélyező neve",
          value: "approval",
        },
        { text: "Státusz", value: "status", width: "180px" },
        {
          text: "Műveletek",
          width: "120px",
          value: "actions",
          sortable: false,
        },
      ];
      return [...approve, ...headers];
    },

    filteredHolidayRequestCollection() {
      if (!this.filterData.month) {
        return this.holidayRequestCollection;
      }

      return this.holidayRequestCollection.filter(
        (item) =>
          item.date_from.substring(5, 7) == this.filterData.month ||
          item.date_to.substring(5, 7) == this.filterData.month
      );
    },
  },
  watch: {
    dialogDelete(val) {
      val || this.handleCloseDelete();
    },
    "$route.params.id": {
      handler: function (id) {
        this.dialogHours = false;
        if (id) {
          this.$nextTick(() => {
            this.editItem(id);
          });
        }
      },
      deep: true,
      immediate: true,
    },

    // selectedHolidayRequests(newVal) {
    //   console.log(newVal);
    //   this.$nextTick(() => {
    //     newVal = newVal.filter((item) => item.status == 0);
    //   });
    // },
  },
  methods: {
    ...mapActions(["fetchUserGroups", "fetchEmployee", "fetchYear"]),

    fetchHolidayRequest() {
      return ApiService.post(
        "humanResources/holiday-request/filter",
        this.filterData
      )
        .then(({ data }) => {
          this.holidayRequestCollection = data.map((item) => {
            item.employee = this.getEmployeeByID(item.employee_id)
              ? this.getEmployeeByID(item.employee_id).fullName
              : "";
            item.approval = this.getEmployeeByID(item.approval_id)
              ? this.getEmployeeByID(item.approval_id).fullName
              : "";
            return item;
          });
        })
        .catch((error) => {
          console.log("Error!: ", error);
        })
        .finally(() => {
          this.loadingTable = false;
        });
    },

    fetchModel() {
      this.fetchYear();
      this.fetchEmployee().then(() => {
        this.employee = this.employeeCollection.find(
          (i) => i.user_id == this.currentUser.id
        );
        if (
          this.employee &&
          !this.permissionCan("approval") &&
          !this.permissionCan("delete")
        ) {
          this.filterData.employee_id = this.employee.id;
        }

        return this.fetchHolidayRequest();
      });
    },
    handleCloseModalForm() {
      this.modalData.dialog = false;
      this.modalData.fullName = null;
      this.fetchModel();

      if (this.$route.name !== this.routeName)
        this.$router.push({ name: this.routeName });
    },
    handleSaveModalForm() {
      this.handleCloseModalForm();
      /*if (item) {
            this.handleClickItem(item);
          }*/
    },

    handleWaitForApproove() {
      this.filterData.employee_id = null;
      this.filterData.status = 0;
      this.fetchHolidayRequest();
    },

    handleMyRequests() {
      if (this.employee) this.filterData.employee_id = this.employee.id;
      this.filterData.status = null;
      this.fetchHolidayRequest();
    },
    formattedDate(d) {
      const year = d.getFullYear().toString();
      const month = (d.getMonth() + 1).toString().padStart(2, "0");
      const dayOfMonth = d.getDate().toString().padStart(2, "0");

      return `${year}-${month}-${dayOfMonth}`;
    },

    closeFormDialog() {
      this.formModal.id = null;
      this.formModal.dialog = false;
      this.formModal.url = "";
      this.formModal.inputText = "";
    },

    saveFormDialog() {
      this.loader = true;

      ApiService.put(this.formModal.url, {
        holiday_requests: [
          {
            id: this.formModal.id,
            justification: this.formModal.justification,
          },
        ],
      })
        .then(() => {
          // this.$emit("saveModalForm");
        })
        .catch((error) => {
          if (error.response) {
            let errors = error.response.data;
            if (errors) {
              for (let field in errors) {
                this.setError(field, errors[field][0]);
              }
            }
          } else if (error.request) {
            // The request was made but no response was received
            console.log(error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
          }
          console.log("Error!: ", error);
          // this.modalData.loading = false;
        })
        .finally(() => {
          this.closeFormDialog();
          this.fetchHolidayRequest();
          this.loader = false;
        });
    },

    handleApprove(item) {
      this.formModal.id = item.id;
      this.formModal.dialog = true;
      this.formModal.url = "humanResources/holiday-request/approve";
      this.formModal.title = "Engedélyezés";
      this.formModal.description = "Írja be az indoklást.";
      this.formModal.justification = "";
    },
    handleReject(item) {
      this.formModal.id = item.id;
      this.formModal.dialog = true;
      this.formModal.url = "humanResources/holiday-request/reject";
      this.formModal.title = "Elutasítás";
      this.formModal.description = "Írja be az indoklást.";
      this.formModal.justification = "";
    },

    handleApproveAll() {
      this.approveAllModal = {
        dialog: true,
        url: "humanResources/holiday-request/approve",
        title: "Biztos engedélyezi az összes kijelölt igényt?",
      };
    },

    handleRejectAll() {
      this.approveAllModal = {
        dialog: true,
        url: "humanResources/holiday-request/reject",
        title: "Biztos elutasítja az összes kijelölt igényt?",
      };
    },

    saveApproveAllModalDialog() {
      this.loader = true;
      let holiday_requests = this.selectedHolidayRequests.map((item) => ({
        id: item.id,
      }));
      ApiService.put(this.approveAllModal.url, {
        holiday_requests,
      }).finally(() => {
        this.fetchHolidayRequest();
        this.loader = false;
      });
      this.closeApproveAllModalDialog();
    },
    closeApproveAllModalDialog() {
      this.approveAllModal.dialog = false;
    },
  },
  mounted() {
    if (this.permissionCan("approval") || this.permissionCan("delete")) {
      this.filterData.status = 0;
    }
    this.fetchModel();
  },
};
</script>

<style scoped>
.grey--text {
  color: #464e5f !important;
  cursor: pointer;
}

.grey--text:hover {
  color: #e33354 !important;
  cursor: pointer;
}

.active {
  color: #e33354 !important;
}
</style>
